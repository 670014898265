<template>
  <div class="d-flex align-content-center align-items-center p-1 brand-display"
       v-bind:class="{'brand-border': border}"
       v-if="id">
    <img :src="brand.logo" :style="style" class="d-dark-none"/>
    <img :src="logoDark" :style="style" class="d-light-none"/>
    <span class="ml-2" v-if="name">{{ bName }}</span>
  </div>
  <div v-else>No Brand</div>
</template>
<script>
import {titleCase} from '@/util'
export default {
  name: 'DisplayBrand',
  props: {

    id: {
      type: String
    },
    border: {
      default: true,
      type: Boolean
    },
    name: {
      default: true,
      type: Boolean
    },
    width: {
      default: 36,
      type: Number
    }
  },
  data() {
    return {}
  },
  computed: {
    style() {
      return `max-width: ${this.width}px`
    },
    brand() {
      let filter = this.$store.state.brands.filter(b => b.id === this.id);
      return filter.length > 0 ? filter[0] : {}
    },
    logoDark() {
      return this.brand.logoDark || this.brand.logo
    },
    bName() {
      return titleCase(this.brand.name)
    }
  },
  methods: {},
  async created() {
  }
}
</script>
<style scoped>
.brand-display {
  padding: 5px;
}
.brand-border {
  border: 1px solid #cccccc;
  border-radius: 10px;
}
</style>