<template>
  <b-form-group :label="label" :class="cls" style="min-width: 300px">
    <div class="d-flex align-content-center align-items-center">
      <img v-if="preview" class="mr-2"
           @click="openPreview"
           :src="preview" width="64" style="margin: 10px 0;border: 2px solid #eee; border-radius: 10px;padding: 10px;">
      <b-form-file v-model="file"
                   :browse-text="browseText"
                   @input="fileSelected"></b-form-file>
    </div>
    <b-modal :id="rndId" size="xl" hide-footer>
      <img :src="preview" class="w-100" />
    </b-modal>
  </b-form-group>
</template>
<script>
import axios from "axios";

export default {
  name: 'FileUpload',
  props: ['value', "title", "resize", "idx", "preview", "noTitle", "cls"],
  data: function () {
    return {
      file: null,
      rndId: `id:${Math.random()}`
    }
  },
  computed: {
    label: function () {
      return this.noTitle ? '' : (this.title || 'Image')
    },
    browseText() {
      if(this.label === 'Image') {
        return 'Select Image'
      }
      if(this.label === 'Video') {
        return 'Select Video'
      }
      return 'Select File'
    }
  },
  methods: {
    async openPreview(){
      this.$bvModal.show(this.rndId)
    },
    async fileSelected(file) {
      let f = new FormData()
      f.append("file", file)
      let saveOnly = false;
      if (!this.resize) {
        saveOnly = true
      }
      let resp = await axios.post(`/api/upload-image?save-only=${saveOnly}`, f)
      this.$emit('input', resp.data.file)
      this.$emit('input2', {url:resp.data.file, idx: this.idx})
    }
  }
}
</script>